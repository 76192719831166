import classNames from 'classnames';
import { GatsbyLinkProps, Link } from 'gatsby';
import React from 'react';
import * as styles from './link_button.module.less';

export const LinkButton: React.FC<GatsbyLinkProps<{}>> = (props) => {
  const { children, to, rel, className } = props;

  return (
    <Link to={to} className={classNames(styles.link, className)} rel={rel}>
      {children}
    </Link>
  );
};
