import { graphql } from 'gatsby';
import React from 'react';
import { ArticleCard } from '../../components/article_card/article_card';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { LinkButton } from '../../components/link_button/link_button';
import { GatsbyPageProps } from '../../gatsby-types';
import Layout from '../../layouts/layout';
import { RoutePaths } from '../../lib/routes';
import { PrismicArticle } from '../../models/prismic_article';
import * as styles from './article_list.module.less';

export const query = graphql`
  query($limit: Int, $skip: Int) {
    allPrismicArticle(
      sort: { fields: data___publication_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

const ArticleList = (props: GatsbyPageProps) => {
  const articles = props.data.allPrismicArticle.nodes as PrismicArticle[];

  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPageNum = currentPage - 1;
  const nextPageNum = currentPage + 1;
  const prevPage =
    prevPageNum === 1
      ? RoutePaths.ARTICLES_LIST
      : `${RoutePaths.ARTICLES_LIST}${prevPageNum.toString()}`;
  const nextPage = `${RoutePaths.ARTICLES_LIST}${nextPageNum.toString()}`;

  return (
    <Layout>
      <ContentWrapper>
        <article className={styles.page}>
          <h2>Articles</h2>
          <ol className={styles.articles}>
            {articles.map((article) => (
              <li className={styles.article} key={article.uid}>
                <ArticleCard article={article} />
              </li>
            ))}
          </ol>
          <nav className={styles.nav}>
            {!isFirst && (
              <LinkButton to={prevPage} rel="prev">
                ← Previous Page
              </LinkButton>
            )}
            {!isLast && (
              <LinkButton to={nextPage} rel="next">
                Next Page →
              </LinkButton>
            )}
          </nav>
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default ArticleList;
